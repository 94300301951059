module.exports = {
  author: "@r-doherty",
  siteTitle: "Ryan J. Doherty - Web Design",
  siteShortTitle: "~/rjd", // Used as logo text in header, footer, and splash screen
  siteDescription:
    "Ryan J. Doherty - Web Design: blazing-fast, single page sites with clean yet expressive designs.",
  siteUrl: "https://ryanjdoherty.com/",
  siteLanguage: "en_US",
  siteIcon: "content/favicon.png", // Relative to gatsby-config file
  seoTitleSuffix: "Freelance Web Design", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"

  splashScreen: true, // Set this to true if you want to use the splash screen

  // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
  // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
  mediumRssFeed:
    "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40ryanjdoherty",
  shownArticles: 3,

  // There are icons available for the following platforms:
  // Medium, GitHub, LinkedIn, XING, Behance
  socialMedia: [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/rjd-dev/",
      backgroundOnHover: "#2867B2",
    },
    {
      name: "Medium",
      url: "https://ryanjdoherty.medium.com/",
    },
    {
      name: "GitHub",
      url: "https://github.com/r-doherty",
      backgroundOnHover: "#0F9D58",
    },
  ],

  navLinks: {
    menu: [
      {
        name: "projects 💯",
        url: "/#projects",
      },
      {
        name: "articles 📝",
        url: "/#articles",
      },
      {
        name: "about 🧔🏻",
        url: "/#about",
      },
    ],
    button: {
      name: "contact 🤙📲",
      url: "/#contact",
    },
  },

  footerLinks: [
    {
      name: "Home",
      url: "/#",
    },
    {
      name: "Projects",
      url: "/#projects",
    },
  ],
}
